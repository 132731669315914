import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"   viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">


<path d="M1955 2350 c-20 -38 -111 -141 -139 -157 -12 -6 -25 -8 -31 -4 -5 5
-23 12 -40 16 -16 5 -34 9 -40 11 -5 2 -53 10 -105 18 -83 13 -107 13 -190 1
-125 -18 -126 -18 -211 -55 -141 -59 -291 -199 -359 -335 -16 -33 -30 -63 -30
-67 0 -4 -5 -19 -12 -35 -38 -95 -49 -287 -23 -403 17 -72 50 -159 76 -204 10
-16 16 -33 13 -38 -3 -4 3 -5 12 -1 12 4 15 3 10 -5 -4 -7 -2 -12 3 -12 6 0
11 -6 11 -12 0 -7 23 -38 50 -68 27 -30 50 -58 50 -61 0 -3 -9 -48 -20 -100
-32 -157 -27 -249 14 -249 15 0 53 41 90 97 58 87 141 145 161 113 4 -6 10 -8
15 -5 5 3 11 1 14 -4 18 -29 305 -38 401 -13 22 6 49 13 60 15 26 7 119 52
172 83 80 47 204 182 254 275 19 37 59 153 59 174 0 12 5 26 11 32 8 8 11 55
10 140 -2 175 -3 182 -18 187 -17 7 -17 36 0 36 8 0 9 2 1 8 -6 4 -24 34 -39
67 -30 63 -82 150 -106 175 -69 73 -89 103 -84 120 21 65 45 303 29 288 -4 -5
-13 -59 -20 -122 -7 -62 -20 -134 -29 -159 l-15 -46 50 -53 c71 -74 125 -160
162 -259 30 -80 32 -91 32 -229 1 -134 -1 -152 -28 -233 -68 -209 -243 -385
-450 -454 -74 -25 -94 -27 -231 -27 -140 0 -155 1 -223 27 l-72 28 -34 -26
c-42 -32 -118 -123 -153 -183 -14 -23 -27 -42 -28 -40 -11 11 30 286 49 338 4
9 -11 32 -40 60 -71 68 -137 176 -170 276 -27 82 -29 100 -29 234 1 138 2 149
33 230 77 205 245 370 446 437 74 25 94 28 226 27 130 0 153 -3 224 -26 l79
-27 36 29 c37 29 106 110 142 168 18 27 19 32 5 32 -8 0 -23 -14 -31 -30z"/>
<path d="M1788 2115 c-3 -3 -16 -8 -29 -10 -23 -5 -62 -45 -53 -54 2 -2 14 5
27 17 13 12 28 22 34 22 6 0 16 7 23 15 12 14 10 22 -2 10z"/>
<path d="M1357 2084 c-208 -50 -393 -237 -442 -446 -19 -81 -19 -196 0 -277
19 -77 82 -200 134 -260 l38 -43 33 47 c66 96 199 169 375 204 80 16 86 19 57
25 l-32 8 6 141 c6 160 9 174 27 159 6 -5 80 -33 162 -61 250 -86 259 -98 227
-284 -30 -174 -46 -191 -227 -237 -146 -38 -285 -82 -351 -111 l-49 -22 50
-13 c70 -17 198 -17 274 1 98 23 210 87 285 161 183 184 228 462 112 693 -30
59 -105 160 -125 169 -4 1 -15 -14 -26 -34 -28 -52 -99 -112 -180 -150 -68
-33 -204 -72 -255 -75 l-25 -1 27 -11 28 -11 -7 -153 c-6 -146 -5 -153 13
-154 10 0 14 -3 7 -5 -6 -3 -22 1 -35 9 -13 9 -86 37 -163 63 -256 86 -269
101 -240 276 31 187 43 199 264 257 166 45 288 84 336 109 l30 16 -50 12 c-68
16 -207 15 -278 -2z m198 -24 c3 -5 -1 -10 -9 -10 -9 0 -16 -4 -16 -8 0 -5
-33 -16 -72 -26 -40 -10 -102 -27 -138 -38 -36 -11 -77 -22 -91 -24 -14 -2
-38 -8 -52 -13 -52 -20 -19 21 46 58 34 19 145 57 187 64 51 9 139 7 145 -3z
m402 -225 c81 -112 122 -255 110 -384 -7 -86 -45 -191 -90 -252 -22 -30 -36
-45 -32 -34 13 39 35 181 35 230 0 66 -25 106 -91 141 -29 16 -55 29 -58 29
-14 2 -62 19 -160 57 -94 37 -102 43 -85 55 10 7 34 18 54 23 77 21 188 92
235 150 17 21 35 35 42 31 6 -3 25 -24 40 -46z m-917 -36 c0 -5 -7 -56 -16
-112 l-16 -102 24 -45 c20 -38 35 -51 103 -86 44 -22 110 -49 148 -59 37 -10
67 -22 67 -26 0 -5 10 -9 23 -9 13 0 32 -7 43 -15 16 -13 17 -16 4 -25 -8 -5
-27 -10 -42 -10 -16 0 -28 -4 -28 -9 0 -5 -12 -12 -27 -16 -45 -10 -157 -82
-197 -125 -20 -22 -42 -40 -49 -40 -10 1 -90 112 -93 129 0 3 -6 20 -13 36
-43 99 -51 306 -17 390 22 51 48 106 60 123 10 15 26 16 26 1z m803 -748 c-22
-23 -115 -80 -140 -86 -10 -3 -45 -11 -78 -20 -33 -8 -94 -14 -135 -14 l-75 1
45 21 c25 12 47 21 50 22 3 1 37 11 75 22 61 19 246 68 268 72 4 0 0 -7 -10
-18z"/>
<path d="M1909 2008 c-7 -18 -14 -36 -17 -40 -2 -5 4 -8 13 -8 9 0 13 5 10 10
-3 6 -2 10 3 10 11 0 33 46 25 54 -13 12 -21 5 -34 -26z"/>
<path d="M1310 1659 c-162 -26 -240 -61 -240 -106 1 -15 4 -14 23 7 33 37 80
55 192 75 112 19 160 34 110 33 -16 0 -55 -4 -85 -9z"/>
<path d="M1870 1405 c-25 -13 -48 -21 -52 -18 -5 2 -8 -1 -8 -6 0 -6 -8 -10
-17 -9 -23 3 -136 -22 -130 -28 3 -3 45 2 93 12 89 16 164 46 164 64 0 6 -1 9
-2 9 -2 -1 -23 -12 -48 -24z"/>
<path d="M1285 1186 c17 -15 46 -35 65 -46 l35 -20 -25 20 c-14 11 -30 24 -35
28 -6 5 -24 17 -40 26 l-30 18 30 -26z"/>
<path d="M1081 999 c-31 -66 -74 -253 -69 -297 3 -22 5 -21 35 19 17 24 41 56
53 72 16 19 17 25 6 21 -8 -3 -18 -14 -21 -25 -4 -11 -11 -17 -16 -14 -5 4
-12 -1 -16 -11 -6 -16 -7 -15 -14 3 -8 22 6 102 26 148 44 100 57 136 52 142
-4 3 -20 -23 -36 -58z"/>
<path d="M1238 914 c-21 -13 -38 -27 -38 -30 0 -6 67 32 85 48 20 17 -11 6
-47 -18z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
